import React from "react"

import CurrentUserAvatar from "~components/shared/current-user-avatar"
import { useUser } from "~config/user-provider"
import { Button } from "~components/shared/todo-lib-react-components/button"
import { componentStyles } from "~components/shared/todo-lib-react-components/hooks/use-theme"
import COPY from "~config/copy-constants"
import Skeleton from "~components/shared/todo-lib-react-components/skeleton"
import { Size } from "~components/shared/todo-lib-react-components/avatar"

export default function HeaderProfilePopoverTrigger() {
    const styles = useStyles()
    const { user, isFetching } = useUser()

    const firstName = user?.first_name ?? COPY.MENU

    return (
        <Button style={styles.root} variant="outline">
            {isFetching ? <Skeleton width="50px" height="16px" /> : firstName}
            &nbsp;
            <CurrentUserAvatar size={Size.Small} />
        </Button>
    )
}

function useStyles() {
    return componentStyles({
        root: {
            paddingRight: 1,
            rounded: "full",
        },
    })
}
