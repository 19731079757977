import React from "react"
import type { ButtonProps } from "@chakra-ui/react"
import { Button as ButtonOg } from "@chakra-ui/react"

import type { PropsWithChildrenAndStyle } from "~components/shared/todo-lib-react-components/types"

interface Props extends PropsWithChildrenAndStyle {
    readonly href: string
    readonly variant?: ButtonProps["variant"]
}

export default function LinkButton({ style, href, ...props }: Props) {
    return (
        <ButtonOg
            sx={style}
            as="a"
            href={href}
            target="_blank"
            colorScheme="brand"
            {...props}
        />
    )
}
