import React from "react"
import { Button as ButtonOg } from "@chakra-ui/react"
import type {
    ButtonProps as ButtonPropsOg,
    SystemStyleObject,
} from "@chakra-ui/react"
import { FaChevronRight } from "react-icons/fa"

import type { PropsWithChildrenAndStyle } from "~components/shared/todo-lib-react-components/types"

/*
    NOTE: We already have a pill button in the shared lib, but there is no
    way to set the size (that prop isn't exposed).
*/

type ChakraAllowedProps = Pick<
    ButtonPropsOg,
    | "variant"
    | "children"
    | "isLoading"
    | "isDisabled"
    | "leftIcon"
    | "rightIcon"
    | "onClick"
    | "className"
>

interface Props extends PropsWithChildrenAndStyle, ChakraAllowedProps {
    readonly isLoading?: boolean
    readonly disabled?: boolean
    readonly testId?: string
}

export function PillButtonExtraSmall({ style, testId, ...props }: Props) {
    return (
        <ButtonOg
            data-testid={testId}
            sx={style}
            {...props}
            rounded="full"
            size="xs"
        />
    )
}

export function PillButtonSmall({ style, testId, ...props }: Props) {
    return (
        <ButtonOg
            data-testid={testId}
            sx={style}
            {...props}
            rounded="full"
            size="sm"
        />
    )
}

export function PillButton({ style, ...props }: Props) {
    return <ButtonOg sx={style} {...props} rounded="full" />
}

export function Button({ style, testId, ...props }: Props) {
    return <ButtonOg data-testid={testId} sx={style} {...props} />
}

export function PrimaryButton({ style, testId, ...props }: Props) {
    return (
        <ButtonOg
            data-testid={testId}
            sx={style}
            {...props}
            colorScheme="brand"
        />
    )
}

export function RowButton({ style, testId, ...props }: Props) {
    const defaultStyle = useRowButtonStyle()

    return (
        <ButtonOg
            data-testid={testId}
            sx={{ ...defaultStyle, ...style }}
            {...props}
            variant="ghost"
            rightIcon={<FaChevronRight />}
        />
    )
}

function useRowButtonStyle(): SystemStyleObject {
    return {
        width: "100%",
        display: "flex",
        justifyContent: "flex-start",
        "& > span:last-child": {
            marginLeft: "auto",
        },
    }
}
