import React from "react"
import {
    RiHeartLine,
    RiMapPin2Line,
    RiInboxArchiveLine,
    RiHome2Line,
} from "react-icons/ri"
import { useLocation, useMatch } from "@reach/router"

import Box from "~components/shared/todo-lib-react-components/box"
import WelcomeModal from "~components/pages/welcome-modal"
import ProfileTab from "~components/shared/tab-bar/profile-tab"
import { useTabletMediaQueries } from "~utils/use-media-queries"
import { useColors } from "~components/shared/todo-lib-react-components/hooks/use-colors"

import TabBarItem, { ICON_SIZE } from "./tab-bar-item"

export default function TabBar() {
    const styles = useStyles()

    const isTabBarHidden = useShouldHideTabBar()
    const itemConfigs = useItemConfigs()

    if (isTabBarHidden) return null

    return (
        <>
            <Box style={styles.container}>
                {itemConfigs.map((config) => (
                    <TabBarItem
                        key={config.title}
                        title={config.title}
                        to={config.to}
                        isActive={config.isActive}
                    >
                        {config.icon}
                    </TabBarItem>
                ))}
                <ProfileTab />
            </Box>
            <WelcomeModal />
        </>
    )
}

function useStyles() {
    const colors = useColors()
    const height = "68px"

    return {
        container: {
            height,
            position: "fixed",
            bottom: 0,
            width: "100vw",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-around",
            userSelect: "none",
            backgroundColor: colors.bgColor,
            borderTop: `1px solid ${colors.borderOpaque}`,
        },
    }
}

function useShouldHideTabBar() {
    const { pathname } = useLocation()
    const { isTabletOrLarger } = useTabletMediaQueries()
    if (isTabletOrLarger) return true

    return ["/listing", "/book", "/group-pay-member", "/checkout-summary"].some(
        (path) => pathname.includes(path)
    )
}

function useItemConfigs() {
    const { pathname } = useLocation()
    const isExploreActive = ["/", "/search"].includes(pathname)
    const isWishlistsActive = useMatch("/wishlists/*")
    const isTripsActive = useMatch("/trips/*")
    const isInboxActive = useMatch("/inbox/*")
    const iconStyle = {
        height: ICON_SIZE,
        width: ICON_SIZE,
    }

    return [
        {
            title: "Explore",
            icon: <RiHome2Line style={iconStyle} />,
            isActive: Boolean(isExploreActive),
            to: "/",
        },
        {
            title: "Wishlists",
            icon: <RiHeartLine style={iconStyle} />,
            isActive: Boolean(isWishlistsActive),
            to: "/wishlists",
        },
        {
            title: "Trips",
            icon: <RiMapPin2Line style={iconStyle} />,
            isActive: Boolean(isTripsActive),
            to: "/trips",
        },
        {
            title: "Inbox",
            icon: <RiInboxArchiveLine style={iconStyle} />,
            isActive: Boolean(isInboxActive),
            to: "/inbox",
        },
    ]
}
