import React from "react"

import { useDesktopMediaQueries } from "~utils/use-media-queries"
import { getAvailableListingDates } from "~utils/helpers"
import Calendar from "~components/shared/calendar"
import { CalendarOrientation } from "~config/constants"

interface Props {
    readonly minDate?: Date
    readonly includeDates?: Date[]
    readonly value?: Date[]
    // TODO create an intermidiary onChange so that the params passed to it
    // are simpler than the types user by baseui. This will simplify usage
    // of this component
    readonly onChange?: (newDate: {
        date: (Date | undefined | null)[] | Date | undefined | null
    }) => void
}

export default function AvailabilityCalendar({
    minDate,
    includeDates,
    value,
    onChange,
}: Props) {
    const { isDesktopOnly } = useDesktopMediaQueries()

    const availableDates = getAvailableListingDates({
        includeDates: includeDates || [],
        minDate: minDate || new Date(Date.now()),
    })

    return (
        <Calendar
            range
            minDate={minDate}
            includeDates={availableDates.length ? availableDates : undefined}
            orientation={CalendarOrientation.Horizontal}
            monthsShown={isDesktopOnly ? 2 : 1}
            value={value}
            onChange={onChange}
            autoFocusCalendar
            overrides={{
                CalendarHeader: { style: { backgroundColor: "transparent" } },
                MonthHeader: { style: { backgroundColor: "transparent" } },
                Day: {
                    style: {
                        fontWeight: 600,
                        height: "auto",
                    },
                },
                DayLabel: { style: { fontSize: "11px" } },
            }}
        />
    )
}
