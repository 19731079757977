import React from "react"

import type {
    ListingOutfitterFieldsFragment,
    PublicOutfitterFieldsFragment,
} from "~graphql/generated/graphql"
import StatefulPopover from "~components/shared/stateful-popover"
import { TEST_IDS } from "~config/test-ids"
import { componentStyles } from "~components/shared/todo-lib-react-components/hooks/use-theme"
import { Text } from "~components/shared/todo-lib-react-components/text"

import OutfitterPopoverContent from "./outfitter-popover-content"

interface Props {
    readonly outfitter?:
        | ListingOutfitterFieldsFragment
        | PublicOutfitterFieldsFragment
    readonly namePrefix?: string
}

export default function OutfitterPopover({
    outfitter,
    namePrefix = "",
}: Props) {
    const styles = useStyles()
    if (!outfitter) return null

    return (
        <StatefulPopover
            renderContent={() => (
                <OutfitterPopoverContent outfitter={outfitter} />
            )}
            overrides={getPopOverOverrides()}
            placement="bottomLeft"
            triggerType="hover"
            showArrow
            returnFocus
            autoFocus
        >
            <div
                style={styles.outfitter}
                data-testid={TEST_IDS.OUTFITTER_POPOVER}
            >
                {namePrefix}
                <Text style={styles.outfitterName}>{outfitter.name}</Text>
            </div>
        </StatefulPopover>
    )
}

function useStyles() {
    return componentStyles({
        outfitter: {
            fontSize: "14px",
            width: "fit-content",
            display: "flex",
        },
        outfitterName: {
            textDecorationLine: "underline",
            textDecorationStyle: "dotted",
            marginLeft: "0.5rem",
        },
    })
}

function getPopOverOverrides() {
    return {
        Body: {
            style: () => ({
                borderRadius: "12px",
                overflow: "hidden",
            }),
        },
    }
}
