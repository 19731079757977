import React from "react"
// TODO use @mallardbay/lib-react-components
// eslint-disable-next-line no-restricted-imports
import { Calendar as CalendarOg } from "baseui/datepicker"
// TODO use @mallardbay/lib-react-components
// eslint-disable-next-line no-restricted-imports
import type { CalendarProps } from "baseui/datepicker"

export default function Calendar(props: CalendarProps) {
    return <CalendarOg {...props} />
}
