import React from "react"

import LinkButton from "~components/shared/todo-lib-react-components/buttons/link-button"
import { componentStyles } from "~components/shared/todo-lib-react-components/hooks/use-theme"

interface Props {
    readonly label?: string | null
    readonly href?: string | null
}

export default function CollectionButton({ label, href }: Props) {
    const styles = useStyles()

    if (!label || !href) return null

    return (
        <LinkButton style={styles.root} href={href} variant="outline">
            {label}
        </LinkButton>
    )
}

function useStyles() {
    return componentStyles({
        root: {
            width: { base: "100%", md: "initial" },
            borderRadius: "full",
            height: "unset",
            paddingY: 4,
        },
    })
}
