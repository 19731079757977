import React from "react"

import HeaderProfileMenuButton from "~components/shared/header/header-profile-menu-button"
import HeaderProfileMenuButtons from "~components/shared/header/header-profile-menu-buttons"
import Skeleton from "~components/shared/todo-lib-react-components/skeleton"
import CurrentUserAvatar from "~components/shared/current-user-avatar"
import COPY_CONSTANTS from "~config/copy-constants"
import { useUser } from "~config/user-provider"
import { getFullName } from "~utils/helpers"
import {
    useFirstSectionButtonConfigs,
    useSecondSectionButtonConfigs,
    useThirdSectionButtonConfigs,
} from "~components/shared/header/header-profile-menu-button-configs"
import { navigateToAccountPage } from "~utils/navigation-helpers"
import {
    TextBold,
    TextSmallSecondary,
} from "~components/shared/todo-lib-react-components/text"
import { VerticalStack } from "~components/shared/todo-lib-react-components/vertical-stack"
import { componentStyles } from "~components/shared/todo-lib-react-components/hooks/use-theme"
import Divider from "~components/shared/todo-lib-react-components/divider"

export default function HeaderProfileButtonMenu() {
    const firstSectionButtonConfigs = useFirstSectionButtonConfigs()
    const secondSectionButtonConfigs = useSecondSectionButtonConfigs()
    const thirdSectionButtonConfigs = useThirdSectionButtonConfigs()

    const handleAccountClick = () => {
        navigateToAccountPage()
    }

    return (
        <>
            <HeaderProfileMenuButton onClick={handleAccountClick}>
                <UserInfo />
                <CurrentUserAvatar />
            </HeaderProfileMenuButton>
            <Divider />
            <HeaderProfileMenuButtons configs={firstSectionButtonConfigs} />
            <Divider />
            <HeaderProfileMenuButtons configs={secondSectionButtonConfigs} />
            <Divider />
            <HeaderProfileMenuButtons configs={thirdSectionButtonConfigs} />
        </>
    )
}

function UserInfo() {
    const styles = useStyles()
    const { user, isFetching, isAdmin } = useUser()
    if (isFetching) return <Skeleton width="50px" height="16px" />

    const userName = getFullName(user) || COPY_CONSTANTS.USER

    return (
        <VerticalStack style={styles.userInfoContainer}>
            <TextBold>{userName}</TextBold>
            {isAdmin && (
                <TextSmallSecondary>{COPY_CONSTANTS.ADMIN}</TextSmallSecondary>
            )}
        </VerticalStack>
    )
}

function useStyles() {
    return componentStyles({
        userInfoContainer: {
            gap: 1,
        },
    })
}
