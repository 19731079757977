import type { IconButtonProps } from "@chakra-ui/react"
import { IconButton as IconButtonOg } from "@chakra-ui/react"
import type { StyleProps } from "@mallardbay/lib-react-components"
import type { MouseEventHandler } from "react"
import React from "react"

import { useColors } from "../hooks/use-colors"

interface Props {
    readonly color?: string
    readonly ariaLabel: string
    readonly icon: React.ReactElement
    readonly onClick?: MouseEventHandler<HTMLButtonElement>
    readonly style?: StyleProps
    // We need both versions. rc-pagination needs disabled.
    readonly isDisabled?: boolean
    readonly disabled?: boolean
    readonly testId?: string
    readonly variant?: IconButtonProps["variant"]
}

export function MediumIconButton({
    color,
    ariaLabel,
    icon,
    onClick,
    style,
    isDisabled,
    disabled,
    testId,
    variant,
}: Props) {
    const colors = useColors()

    return (
        <IconButtonOg
            sx={{
                backgroundColor: colors.bgColor,
                ":hover": {
                    backgroundColor: colors.bgColor,
                    transform: "scale(1.05)",
                },
                ...style,
            }}
            rounded="full"
            size="md"
            colorScheme={color}
            aria-label={ariaLabel}
            icon={icon}
            onClick={onClick}
            isDisabled={isDisabled || disabled}
            data-testid={testId}
            variant={variant}
        />
    )
}
